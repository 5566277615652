.water-slide-div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
}
.water-page-callout-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    height:150px;
    order: 2;
}
.water-page-water-map{
    display: flex;
    flex-direction: row;
    height:60vh;
    width:100vw;
    order: 3;
}
.water-page-water-map-heading{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}
.inner-page-paragraph{
    display: flex;
    flex-direction: row;
    width:45%;
    order:3;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin-top:70px;
}
.inner-page-paragraph p{
font-family:  'Georgia';
    /* font-family:  source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif; */
    font-size: 17px;
    color:#3b3b3b !important;
    line-height: 1.7;
    font-weight: 300;
}
.nodes-container{
    position:absolute;
    z-index: 1000;
    background-color: rgba(2, 2, 2, 0.656);
    height: 100vh;
    width: 100vw;
    top:0;
    left: 0;
}
.nodes-close{
    display: flex;
    flex-direction: row;
    height:40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position:absolute;
    top:10px;
    right:10px;
    background-color: #fffffff5;
    cursor: pointer !important;
}
.node-close-icon{
    vertical-align: middle;
    font-size: 16px;
}
.nodes-close:hover h5{
    color:rgb(60, 104, 123);
}
.save-schema{
    display: flex;
    flex-direction: row;
    height:40px;
    width: 150px;
    justify-content: center;
    align-items: center;
    position:absolute;
    top:10px;
    right:70px;
    background-color: #246879f5;
    cursor: pointer !important;
    color: #fff;
    border-radius: 7px;
    font-style: italic;
}
.save-schema:hover{
    background-color: #1c5b6b;
}
.save-schema h5{
    font-size: 11px;
}
.save-icon{
    margin-right: 5px;
    vertical-align: middle;
}
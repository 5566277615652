.callout-container{
    display: flex;
    flex-direction: row;
    height:100%;
    width:25%;
    justify-content: left;
}
.callout-figure{
    display: flex;
    flex-direction: row;
    order: 1;
    justify-content: left;
    align-items: center;
    width:max-content;
    margin-right: 20px;
    ;
}.callout-figure h1{
    font-size: 30px;
    margin:0;
    color:rgb(44, 104, 113);

}
.callout-narration{
    display: flex;
    flex-direction: row;
    order: 2;
    justify-content: left;
    align-items: center;
    width:60%;
    color:rgb(60, 60, 60);
}
.callout-narration h5{
    font-size: 13px;
    text-align: left;
    font-weight: 300;
    margin:0

}
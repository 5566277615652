.viewport-node{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-around;
    height:max-content;
    padding:15px 15px 15px 0;
    width:max-content;
    border-radius: 0 10px 10px 0;
    background-color: #919191;
}
.viewport-header{
    display: flex;
    flex-direction: row;
    height:20%;
    width:90%;
    margin-bottom: 5%;
    order: 1;
    position: relative;
    top: 10px;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0.5px solid #DC7B7B;
}
.viewport-header p{
    color:#fff;
    font-size: 12px;
}
.viewport-body{
    display: flex;
    flex-direction: column ;
    justify-content: space-around;
    align-items: flex-start;
    width: 85%;
    height:85%;
    order:2;
}
.viewport-handle {
    position: relative;
    top: 15px;
  }
.handle-label {
    margin-left: 15px;
    color: white;
    text-align: left;
    font-size: 10px;
}
.component-node{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height:max-content;
    padding:15px;
    width:263px;
    background-color: #4E6635;
}
.component-node-header{
    display: flex;
    flex-direction: row;
    height:20%;
    width:90%;
    margin-bottom: 5%;
    order: 1;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0.5px solid #CCDAA0;
}
.component-node-header p{
    color:#fff;
    font-size: 12px;
}
.component-node-body{
    display: flex;
    flex-direction: column ;
    justify-content: space-around;
    align-items: flex-start;
    width: 85%;
    height:85%;
    order:2;
}
.component-node-input{
    order: 1;
    width:95%;
    height:29px;
    margin-bottom: 10px;
}
.component-node-body input{
    border-color: #CCDAA0 !important;
    border-width: 1px;
    border-style:solid;
    background-color: transparent;
    border-radius: 7px;
    outline: none;
    padding-left: 5% !important;
    color:white;
    font-size: 11px;
    margin-bottom: 10px;
}
.component-node-body input::placeholder {
    opacity: 0.5;
    color: rgb(244, 244, 244);
  }
.title-target-handle{
    height:100% !important;
    width:24px !important;
    border-radius:  0 8px 8px 0  !important;
    background-color: #CCDAA0 !important;
    border: none !important;
}
.data-source{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height:max-content;
    padding:15px;
    width:263px;
    background-color: #973D3D;
}
.data-source-header{
    display: flex;
    flex-direction: row;
    height:20%;
    width:90%;
    margin-bottom: 5%;
    order: 1;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0.5px solid #DC7B7B;
}
.data-source-header p{
    color:#fff;
    font-size: 12px;
}
.data-source-body{
    display: flex;
    flex-direction: column ;
    justify-content: space-around;
    align-items: flex-start;
    width: 85%;
    height:85%;
    order:2;
}
.data-source-url-input{
    order: 1;
    width:95%;
    height:29px;
    margin-bottom: 10px;
}
.data-source-body input{
    border-color: #DC7B7B !important;
    border-width: 1px;
    border-style:solid;
    background-color: transparent;
    border-radius: 7px;
    outline: none;
    padding-left: 5% !important;
    color:white;
    font-size: 11px;
}
.data-source-or{
    order:2;
    display: flex;
    flex-direction: row;
    height: 15px;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.data-source-body h4{
    font-size: 10px;
    color:white;
}
.data-source-body input::placeholder {
    opacity: 0.5;
    color: rgb(221, 221, 221);
  }
.data-source-button{
    display: flex;
    width:100%;
    flex-direction: row;
    height:30px;
    background-color: #BC7C7C;
    border-radius:7px;
    border: none;
    color: white;
    justify-content: center;
    align-items: center;
    order: 3;
}
.data-source-target-handle{
    height:100% !important;
    width:24px !important;
    border-radius:  0 8px 8px 0  !important;
    background-color: #C37979 !important;
    border: none !important;
}
.data-source-dropdown{
    width:100%;
    height:29px;
    background-color: transparent;
    border-radius: 7px;
    border-color: #DC7B7B;
    outline: none;
    color:#fff
}
.data-operation{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height:max-content;
    padding:15px;
    width:263px;
    background-color: #3b757eca;
}
.data-operation-header{
    display: flex;
    flex-direction: row;
    height:20%;
    width:90%;
    margin-bottom: 5%;
    order: 1;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0.5px solid #52949E;
}
.data-operation-header p{
    color:#fff;
    font-size: 12px;
}
.data-operation-body{
    display: flex;
    flex-direction: column ;
    justify-content: space-around;
    align-items: flex-start;
    width: 85%;
    height:85%;
    order:2;
}
.data-operation-url-input{
    order: 1;
    width:95%;
    height:29px;
    margin-bottom: 10px;
}
.data-operation-body input{
    border-color: #80a5a5 !important;
    border-width: 1px;
    border-style:solid;
    background-color: transparent;
    border-radius: 7px;
    outline: none;
    padding-left: 5% !important;
    color:white;
    font-size: 11px;
}
.data-operation-or{
    order:2;
    display: flex;
    flex-direction: row;
    height: 15px;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.data-operation-body h4{
    font-size: 10px;
    color:white;
}
.data-source-button p{
    font-size: 9px;
    color: #fff;
}
.data-operation-body input::placeholder {
    opacity: 0.5;
    color: rgb(221, 221, 221);
  }
.data-operation-target-handle{
    height:100% !important;
    width:24px !important;
    border-radius:  0 8px 8px 0  !important;
    background-color: #52949E !important;
    border: none !important;
}
.data-operation-dropdown{
    width:100%;
    height:29px;
    background-color: transparent;
    border-radius: 7px;
    border-color: #52949E;
    outline: none;
    color:#fff
}
.node-source{
    display: flex;
    flex-direction: row;
    min-height:57px;
    height: max-content;
    width:90%;
    background-color: #fff;
    border-radius: 7px;
    align-items: center;
    justify-content: flex-start;
    margin-top:10px;
    cursor: grab;
    padding: 2px 4px 4px 0;
}
.node-source:hover{
    background-color: #f3f3f3;
}
.node-source-draggable{
    display: flex;
    order: 1;
    width:3%;
    height:90%;
    border-radius: 7px;
    margin-left: 2%;
    margin-right:2%;
}
.node-source-icon{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height:90%;
    width:50px;
    order: 2;
}
.datasource{
    color:#973D3D;
}
.datasource-bg{
    background-color:#973D3D;
}
.elementnode{
    color:#AB713F;
}
.elementnode-bg{
    background-color:#AB713F;
}
.datawrangle{
    color:#3B757E
}
.datawrangle-bg{
    background-color:#3B757E
}
.viewport{
    color:#9b9b9b
}
.viewport-bg{
    background-color:#9b9b9b
}
.uicomponent{
    color:#4E6635 ;
}
.uicomponent-bg{
    background-color:#4E6635 ;
}
.node-source-details{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    height: 90%;
    width:75%;
    order: 3;
}
.node-source-details h5{
    font-size: 13px;
    color:#313030;
}
.node-source-details p{
    font-size: 11px;
    color:#AEA6A6;
}

.element-node{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height:max-content;
    padding:15px;
    width:263px;
    background-color: #AB713F;
}
.element-node-header{
    display: flex;
    flex-direction: row;
    height:20%;
    width:90%;
    margin-bottom: 5%;
    order: 1;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 0.5px solid #E0A877;
}
.element-node-header p{
    color:#fff;
    font-size: 12px;
}
.element-node-body{
    display: flex;
    flex-direction: column ;
    justify-content: space-around;
    align-items: flex-start;
    width: 85%;
    height:85%;
    order:2;
}
.element-node-input{
    order: 1;
    width:95%;
    height:29px;
    margin-bottom: 10px;
}
.element-node-body input{
    border-color: #E0A877 !important;
    border-width: 1px;
    border-style:solid;
    background-color: transparent;
    border-radius: 7px;
    outline: none;
    padding-left: 5% !important;
    color:white;
    font-size: 11px;
}
.element-node-body h4{
    font-size: 10px;
    color:white;
}
.element-node-body input::placeholder {
    opacity: 0.5;
    color: rgb(221, 221, 221);
    font-size: 9px;
  }
.element-node-button{
    display: flex;
    width:100%;
    flex-direction: row;
    height:30px;
    background-color: #E0A877;
    border-radius:7px;
    border: none;
    color: white;
    justify-content: center;
    align-items: center;
    order: 3;
}
.element-node-target-handle{
    height:100% !important;
    width:24px !important;
    border-radius:  0 8px 8px 0  !important;
    background-color: #E0A877 !important;
    border: none !important;
}
.element-node-dropdown{
    width:100%;
    height:29px;
    background-color: transparent;
    border-radius: 7px;
    border-color: #E0A877;
    outline: none;
    color:#fff
}
option{
    font-size: 9px !important;
}
.dropdown-label{
    font-size: 12px;
    color:#fff;
    margin-bottom: 5px;
}
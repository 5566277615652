.nav-item {
	margin: .5em;
	/* justify-items: right; */
	font-size: 13px;
	padding: 10px;
    font-family: 'Cera Pro2';
}

.currentitem{
    border-bottom: 2px solid rgb(51, 151, 146);
}
.notification{
    color:rgb(77, 137, 205) ;
    font-size: 22px;
    cursor: pointer;
}
.notification:hover{
    color:rgb(77, 137, 205) !important;
    
}
.drawer{
    padding:15px;
}
.navbar {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 60px;
	background-color: rgb(255, 255, 255);
	width: 100vw;
	/* margin: auto; */
    position: fixed;
    top:0px;
    z-index: 4;
	/* margin-top:15px; */
	margin-bottom: 0;
    /* -webkit-box-shadow: -1px 7px 21px -8px rgba(148,148,148,1);
    -moz-box-shadow: -1px 7px 21px -8px rgba(148,148,148,1);
    box-shadow: -1px 7px 21px -8px rgba(148,148,148,1); */
    
}
.navbar-alt{
    display: flex;
	flex-direction: row;
	align-items: center;
	height: 40px;
	background-color: rgba(255, 255, 255, 0);
	width: 100vw;
	/* margin: auto; */
    position: fixed;
    top:0px;
    z-index: 4;
	/* margin-top:15px; */
	margin-bottom: 0;
   
}
.n-logo {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	order: 1;
	margin-left: 20px;
	flex-basis: 20%;
}

.n-items {
	display: flex;
	flex-direction: row;
	justify-content: center;
    align-items: center;
	order: 2;
	flex-basis: 70%;
    color:black
}
.n-items-alt {
	display: flex;
	flex-direction: row;
	justify-content: center;
    align-items: center;
	order: 2;
	flex-basis: 60%;
    color:rgb(255, 250, 250)
}
.user-logo{
    display: flex;
    flex-direction: row;
    order:3;
    flex-basis: 30%;
    justify-content: flex-end;
    align-items: center;
    margin-right:20px;    
}
.login-button{
    color:rgb(44, 104, 113);
}
.partner-register{
    width:150px;
    height:35px;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
    color: 1px solid rgb(255, 255, 255) !important;
    background-color:rgb(44, 104, 113);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left:20px;
}
.partner-register h5{
font-size: 12px;
color:#fff
}
.n-logo img {
	height: 50px;
	width: auto;
}

.items{
    display:flex !important;
    flex-direction:column !important;
    align-items:flex-start !important;
    height:max-content !important;
    margin-top:230px !important;
    background-color: rgb(255, 255, 255);
    position:absolute !important;
    border-radius: 10px;
    padding: 10px;
    width:max-content !important;
    box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}
.items-alt{
    display:flex !important;
    flex-direction:column !important;
    align-items:flex-start !important;
    height:max-content !important;
    margin-top:230px !important;
    background-color: rgb(255, 255, 255);
    position:absolute !important;
    border-radius: 10px;
    padding: 10px;
    width:max-content !important;
    box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
}
.items h4{
    font-size:12px;
    font-weight:300;
    margin-bottom: 0;
text-align:left
}
.items a{
    cursor:pointer;
}
.items a h4:hover{
    color:rgb(61, 142, 209) !important;
    font-weight:bold;
}
.nav-menu{
	display:flex !important;
    flex-direction:column !important;
    align-items:flex-start !important;
    height:max-content !important;
    width:100px;
    margin-top:5px !important;
    background-color: rgb(255, 255, 255);
    position:absolute !important;
    border-radius: 10px;
    padding: 10px;
    z-index: 2;
    box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-webkit-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);
	-moz-box-shadow: -5px 4px 16px -4px rgba(33, 33, 33, 0.63);

}
.nav-menu h4{
    font-size:11px;
    font-weight:500;
    margin-bottom: 0;
    text-align:left;
    font-family:Poppins;
}
.nav-menu h4:hover{
    cursor: pointer;
    color:rgb(96, 175, 96);
}
.nav-menu a h4:hover{
    color:rgb(61, 142, 209) !important;
    font-weight:bold;
    cursor: pointer;
}
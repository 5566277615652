.project-view{
    width:100vw;
    margin-top:10vh;
    display:flex;
    flex-direction:column;
}

.tabs-div{
    order:1;
    display:flex;
    flex-direction:row;
    width:100%;
    height:10vh;
    /* background-color:rgb(218, 218, 218); */
    align-items:center;
    justify-content:center;
}
.tabs-div1{
    display:flex;
    flex-direction:row;
    order:1;
    flex-basis:20%;
    height:80%;
}
.tabs-div2{
    display:flex;
    flex-direction:row;
    order:2;
    flex-basis:70%;
    height:80%;
    margin-left:50px
}
.main-sidemenu{
    order:2;
    display:flex;
    flex-direction:row;
    height:77vh;
    width:100%;
    margin-top:3vh;
}
.side-menu{
    display:flex;
    flex-direction:column;
    height:inherit;
    order:1;
    flex-basis:20%;
    justify-content:center;
    align-items:center;
    /* background-color:grey; */
}
.sidemenu-div1{
    display:flex;
    flex-direction:column;
    align-items:center;
    order:1;
    height:70%;
    width:80%;
}
.sidemenu-div2{
    display:flex;
    flex-direction:column;
    order:2;
    height:20%;
    width:80%;
    margin-top:30px;
}
.main-view{
    display:flex;
    flex-direction:column;
    height:inherit;
    flex-basis:75%;
    margin-left:50px;
    background-color:#F3F3F3;
    order:2;
    align-items:center;
}
.mainview-heading{
    display:flex;
    flex-direction:row;
    height:60px;
    width: 95%;
    margin-top:40px;
    order:1;
}
.mainview-heading-componentheading{
    display:flex;
    flex-basis: 50%;
    order:1;
    align-items:flex-start;
}
.mainview-heading-deletecomponent{
    display:flex;
    flex-direction:row;
    flex-basis: 50%;
    order:2;
    justify-content:flex-end;
}
.mainview-items{
    display:flex;
    flex-direction:column;
    width: 95%;
    height:85%;
    order:2;
    margin-top:15px;
    overflow:scroll;
}
.main-item{
   margin-bottom:20px;
   align-items:center;
   justify-content:flex-start;
}

.main-item h4{
    margin:0;
}
.sub-activity{
    display:flex;
    flex-direction:row;
    width:100%
}
.activity-details{
   display:flex;
   flex-direction:column;
   order:1;
   flex-basis:60%;
   justify-content:left !important;
   align-items:left !important;
   text-align:left;
   margin-left:20px;
}
.highest-modal{
    z-index:150 !important;
}
.ongoing-works{
    border:1px solid rgb(236, 156, 82);
    background-color:rgba(236, 156, 82, 0.343);
    border-radius:10px;
    display:flex;
    flex-direction:column;
    height:40px;
    width:90%;
    border-radius:10px;
    order:3;
    color:rgb(236, 156, 82);
    cursor:pointer;
    margin:30px 0 0 20px;
    justify-content:center;
    align-items:center;
}
.adding-site{
    margin-top:20px !important;
}
.isdone{
    display:flex;
    order:2;
    flex-basis :40%;
    flex-direction:row;
    align-items:center;
    justify-content:right;
    margin-left:30px;
}
.progressalert{
    margin-top:20px;
   margin-left:20px;

}
.progressalert h5{
margin:0;
text-align:left;
}
.done{
    color:rgb(74, 155, 74);
    font-size: 20px;
    margin-right:20px;
}
.overdue{
    color:rgb(183, 119, 41);
    font-size: 20px;
    margin-top:5px;
    margin-right:20px;
}
.dashboard-div{
    display:flex;
    flex-direction: column;
    margin-top:10vh;
    width:100vw;
}
.dashboard-div1{
    display:flex;
    flex-direction:row;
    order:1;
    width:95vw;
    height:250px;
    margin-left:20px;
    /* background-color:grey */
    overflow:scroll;
}
.dashboard-div2{
    display:flex;
    flex-direction:column;
    order:2;
    width:95vw;
    justify-content:flex-start;
    align-items:flex-start;
    height:max-content;
    margin-left:30px;
    /* overflow: hidden; */
}
.numbers-div{
    display:flex;
    flex-direction:row;
    order:1;
    height:100%;
    flex-basis:80%;
    align-items:center;
    justify-content:flex-start;
}
.number{
    display:flex;
    flex-direction:column;
    width:50%;
    height:100%;
}
.dash-filters{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items:center;
    height:50%;
}
.dash-filter{
    height:10px
}
.number h1{
    color:rgb(72, 145, 72);
    font-size:55px !important;
    margin-top:0;
}
.number h2{
    margin-top:0;
    text-align: left;
}
.number h5{
    margin-top:0;
    text-align: left;
    font-weight: 300;
    font-size: 16px;
}
.task-div{
    display:flex;
    flex-direction:row;
    order:1;
    width:100% !important;
    height:100%;
    /* background-color:grey; */
    margin-top:10px;
    align-items: center;
    justify-content:space-around
}
.overdue-tasks{
    display:flex;
    flex-direction:column;
    order:2;
    height:100%;
    flex-basis:48%;
}
a{
    text-decoration:none;
    color:inherit;
}
.almostdue-tasks{
    display:flex;
    flex-direction:column;
    order:1;
    height:100%;
    flex-basis:48%;
    /* overflow:scroll; */
}
.tasks-heading{
    order:1;
    text-align:left;
}
.tasks-heading h5{
    margin-left:15px;
}
.heading-tab h5,h6{
    margin:0
}
.due-date{
    color:rgb(70, 156, 70);
}
.the-almostdue{
    order:2;
    overflow:scroll;
}
.the-overdue{
    order:1;
    overflow:scroll;
}
.alert{
    margin-bottom:10px;
}
.sitefilter-div{
    display:flex;
    flex-direction:column;
    order:1;
    height:100%;
    flex-basis:20%
}
.site-div{
    display:flex;
    flex-direction:column;
    width:100%;
    height:67%;
    margin-top:20px;
    overflow:scroll;
    order:2;
   
}
.single-site{
    display:flex;
    flex-direction:column;
    width:100%;
    height:100px;
    background-color:#F3F3F3;
    text-align:left;
    margin-bottom:10px;
    cursor: pointer;
}
.single-site:hover{
    background-color:#dcdcdc;
}
.single-site h5{
    margin-left:20px;
}
.add-deployment{
    display:flex;
    flex-direction:column;
    background-color:rgb(232, 232, 232);
    color:rgb(73, 73, 73);
    justify-content:center;
    align-items:center;;
    border-radius:10px;
    cursor:pointer;
    height:40px;
}
.add-deployment:hover{
color:#fff;
background-color: rgb(73, 126, 73);
}
.gantt-view{
display:flex;
flex-direction:column;
height:90vh;
width:95vw;
}
/* * {
    outline: 1px solid #f00 !important;
  } */
.gantt-div1{
    display:flex;
    flex-direction:row;
    height:10%;
    align-items:center;
    justify-content:flex-start;
    width:100%;
    order:1;
    margin-bottom:20px;
    margin-left: 20px;
}
.gantt-div2{
    flex-direction:row;
    height:85%;
    align-items:left;
    justify-content:center;
    margin-left: 20px;
    width:100%;
    order:1
}
.switch-view{
    width:200px;
    height:40px;
    font-family: 'Cera Pro2';
    font-size:12px;
    color:rgb(29, 28, 28);
    background-color: #aed3e9a0;
    border-radius:10px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    cursor:pointer;
}
.gantt-sitename{
    order:2;
    width:500px;
    height:40px;
    display:flex;
    flex-direction:column;
    align-items:left;
    justify-content:center;
    margin-left: 30px;
    text-align: left;
}
.select-view{
    order:3;
    width:200px;
    height:40px;
    display:flex;
    flex-direction:column;
    align-items:left;
    justify-self:flex-end;
    margin-left: 30px;
    text-align: left;
    position: absolute;
    right:100px;
}
.switch-view:hover{
    background-color:#aed3e9
}
.gantt-chart-container{
    display: flex;
    /* height:100vh; */
    width:100vw;
    flex-direction:column;
    margin-left: 100px;
    margin-top: 100px;
    justify-content: center;
    align-items: flex-start;
    overflow-y: scroll !important;
  }
  .gantt-chart-container-header{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    order: 1;
    height:20%;
    width: 50%;
  }
  .gantt-chart-container-header h5{
    font-weight: 400;
    text-align: left;
  }
  .gantt-chart-container-gantt{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    order: 2;
    /* overflow: scroll; */
  }
  .gantt-chart-container-gantt h4{
    color:rgb(27, 110, 80);
  }
  ._3eULf{
    width:100vw !important;
  }
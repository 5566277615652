.nodes-sources-container-open{
    display: flex;
    flex-direction: column;
    width:20vw;
    height:100vh;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #dedbd755;
    border-radius: 7px;
}
.nodes-sources-container-closed{
    display: flex;
    flex-direction: column;
    width:40px;
    height:40px;
    border-radius: 50%;
    position: absolute;
    top: 5vh;
    left: 30px;
    background-color: #dedbd7;
}

.section-container h2{
    font-family: "Fira Sans Condensed", sans-serif;
    text-decoration: underline red;
}
.section-container{
    display: flex;
    flex-direction: column;
    width: 90vw;
    order: 1;
    /* background-color: #f9f9f9 ; */
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
}
.section-container-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height:70px;
    width: 90%;
    order: 1;
}
.section-card-content h3{
    font-family: "Fira Sans Condensed";
    color:#222;
    margin:10px 0 0 0

}
.section-card-content p{
    font-family: "Fira Sans Condensed";
    color:#7a7a7a;
    font-size: 18px;
    margin-top:10px
}
.section-container-body{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center !important;
    height:80%;
    width: 90vw;
    order: 2;
}
.section-container-body a{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.section-card{
display: flex;
flex-direction: row;
height:180px;
width:75%;
justify-content: flex-start;
align-items: center;
background-color: #fff;
margin-bottom:50px;
box-shadow: 0px 1px 7px 1px rgba(219,219,219,0.75);
-webkit-box-shadow: 0px 1px 7px 1px rgba(219,219,219,0.75);
-moz-box-shadow: 0px 1px 7px 1px rgba(219,219,219,0.75);
}
.section-card:hover{
    
}
.section-card-thumbnail{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    order: 1;
    flex-basis: 35%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 5%;
    border-right:.5px dashed rgb(207, 207, 207);
    overflow: hidden;
}
.thumbnail{
    overflow-y: hidden !important;
    max-width: 100%;
    object-fit: cover;
}
.section-card-content{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    order:2;
    flex-basis:60%;
    height: 100%;
}
.tags{
    position: absolute;
    display: flex;
    min-width: 60px;
    width: max-content;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height:20px;
    border-radius: 0 0 0 5px;
    top: 0;
    right:0;
    padding:0 10px
}
.tags-tool{
    background-color: rgba(126, 200, 237, 0.38);
    color:rgb(36, 99, 130);
}
.tags-story{
    background-color: rgba(237, 194, 126, 0.38);
    color:rgb(137, 95, 39);
}
.tags-charts{
    background-color: rgba(218, 237, 126, 0.38);
    color:rgb(83, 137, 39);
}
.tags-map{
    background-color: rgba(198, 126, 237, 0.38);
    color:rgb(114, 39, 137);
}
.top-spacing{
    margin-top:100px;
    order:1
}
.tags h5{
    font-size: 11px;
    font-family: "Fira Sans Condensed";
}
.section-card-project-name{
    display: flex;
    flex-direction: row;
    order: 1;
    max-height:30% !important;
    flex-basis: 100%;
    justify-content: center;
    align-items: flex-end;
    color:rgb(167, 167, 167);
}
.section-card-project-name h5{
    font-family: "Fira Sans Condensed" !important;
}
.section-card-content-details{
    display: flex;
    flex-direction: column;
    order: 2;
    height:70%;
    flex-basis: 100%;
    justify-content:flex-start;
    align-items: flex-start;
}
.tags-filter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    width: max-content;
    padding:0 8px;
    border-radius: 50px;
    height:30px;
    margin-right:10px;
}
.tags-filter h5{
    font-size: 12px;
}
.map-settings-div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width:100%;
    height:100%;
}
.layer-settings{
    display: flex;
    flex-direction: column;
    order:1;
    height:10%;
    width: 90%;
    margin-left:5%;
    margin-top:30px
}
.admin-settings{
    display: flex;
    flex-direction: column;
    order:2;
    height:7%;
    width: 90%;
    margin-left:5%;
    margin-top:30px
}
.hydro-settings{
    display: flex;
    flex-direction: column;
    order:3;
    height:50%;
    width: 90%;
    margin-left:5%;
    margin-top:30px
}
.map-settings-div h2{
    color:#367e7f;
    font-size: 13px;
    font-weight: 500;
}
.map-settings-div h5{
    font-size: 13px;
    margin:0
}
.css-ahj2mt-MuiTypography-root{
    font-family: 'Poppins' !important;
    font-size: 12px !important;
}
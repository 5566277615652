.slide-3-informatic-div{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: max-content;
    background-color: rgb(255, 255, 255);
    width:100%;
    border-radius: 4px;
    padding: 20px 0;
    box-shadow:  rgba(0, 0, 0, 0.1) 0px 3px 6px -1px, rgba(0, 0, 0, 0.1) 0px 5px 10px -2px, rgba(5, 0, 81, 0.1) 0px 0px 20px -5px;
}
.slide-3-informatic-header {
    order: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height:70px;
    width: 90%;
    border-bottom: 1px solid rgb(165, 165, 165);
    /* font-style: italic; */
}
.informatic-header h5{
    color:rgb(63, 106, 135)
}
.slide-3-informatic-body{
    order: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height:max-content !important;
    width: 90%;
}
.informatic-div{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100% !important;
    background-color: #ffffff !important;
    width:100%;
    border-right: 1px solid rgb(229, 229, 229);
}
.informatic-header {
    order: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height:10%;
    width: 90%;
    border-bottom: 1px solid rgb(165, 165, 165);
    /* font-style: italic; */
}
.informatic-header h5{
    color:rgb(63, 106, 135)
}
.informatic-body{
    order: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height:85%;
    width: 90%;
    /* font-style: italic; */
}
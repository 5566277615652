.square-point{
    display: flex;
    height:9px;
    width:9px;
    background-color: rgb(219, 178, 96);;
    border-radius: 2px;
}
.circle-point{
    display: flex;
    height:9px;
    width:9px;
    background-color: rgb(41, 41, 41);;
    border-radius: 50%;
}
.slide-3-hightlight-container{
    display: flex;
    flex-direction: row;
    width:100%;
    height: max-content;
    margin: 7px 0;
    
}
.slide-3-point-container{
    order: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 10%;
}
.slide-3-text-highlight-container{
    display: flex;
    order: 2;
    width:85%;
    height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.slide-3-text-highlight-container p{
    font-size: 13px;
    vertical-align: text-top;
}
.page-header-container{
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    order:1;
    height:70vh;
    width:90vw;
    margin-top: 8vh;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom:60px;
}
  .background-tint {
    background-color: rgba(53, 53, 53, 0.5);
    background-blend-mode: multiply;
  }
  .page-header-title{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    padding:30px;
  }
  .page-header-title h2{
    color:#fff;
    text-align: left;
    font-family: "Fira Sans Condensed";
    font-size: 46px;
    /* background-color: rgba(213, 95, 68, 0.521); */
  }
  
.map{
    display:flex;
    flex-direction:column;
    justify-content: flex-start;
    position: relative;
    order:1;
    margin:50px 0;
    /* border:1px solid grey; */
    box-shadow: 0px 0px 12px 0px rgba(194,194,194,0.75);
-webkit-box-shadow: 0px 0px 12px 0px rgba(194,194,194,0.75);
-moz-box-shadow: 0px 0px 12px 0px rgba(194,194,194,0.75);
}
.slide-top-panel{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height:max-content !important;
    width: 100%;
    order: 1; 
    margin-top: 80px;
}
.map-filters-filter-pane{
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 50%;
    align-items: flex-end;
    margin-top: 8vh;
}
.slide-title-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    order:1;
    width:65% !important;
    height:120px;
    text-align: left;
    margin-left: 5% ;
    margin-bottom:15px ;
}
.slide-title{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    order:1;
    /* width:max-content; */
    margin-bottom: 15px !important;
    position: relative;
}
.slide-title h3{
    font-size: 24px;
    /* font-family:Arial, Helvetica, sans-serif; */
    font-weight: 600;
}
.slide-title h3:hover{
    color: rgb(0, 0, 0);
    text-decoration: underline;
    cursor: pointer;
}
.slide-title-header{
    display: flex;
    flex-direction: row;
    order: 1;
    height: max-content;
    align-items: center;
}
.slide-title-text{
    display: flex;
    order: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: inherit;
}
.slide-title-text h3{
    vertical-align: middle;
    line-height: inherit;
    font-family: 'Lato';
}
.slide-title-class{
    display: flex;
    flex-direction: row;
    height: 22px;
    order: 2;
    align-items:center !important;
    justify-content: center;
    width:100px;
    padding:0 10px 0 10px;
    border-radius: 10px;
    background-color: rgba(189, 163, 77, 0.403);
    margin-left: 20px;
}
.slide-title-class h5{
    font-size: 10px !important;
    font-weight: 600 !important;
    color: rgb(150, 106, 41) !important;
}
.slide-title-description{
    display: flex;
    flex-direction: row;
    order: 2;
    height: max-content;
}
.slide-title h5{
    font-size: 17px;
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-weight: 400;
    color:rgb(61, 61, 61)
}
.slide-legend{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    order:3;
    width:90%;
    height:max-content !important;
    margin:0px 0 0px 5% !important
}
.slide-callout-container{
    display: flex;
    flex-direction: row;
    order: 2;
    width: 90%;
    height: 50%;
    align-items: flex-end; 
    justify-content: left;
}
.map-heading-dropdown{
    display: flex;
    flex-direction: column;
    height:max-content;
    align-items: center;
    justify-content: space-around;
    width:100%;
    overflow-y: scroll;
    background-color:#ffffff;
    border-left:1px solid rgb(211, 211, 211);
    border-bottom:1px solid rgb(211, 211, 211);
    border-right:1px solid rgb(211, 211, 211);
    position: absolute;
    top: calc(100% + 3px);
    z-index: 10;
    border-radius: 0 0 10px 10px;
}
.map-heading-dropdown-item{
display:flex;
flex-direction: column;
justify-content: space-around;
height:70px;
width: 95%;
margin-bottom: 10px;
border-bottom: 0.5px solid rgb(196, 196, 196);
}
.map-heading-dropdown-item h4{
    color:rgb(1, 1, 1);
    font-size: 13px;
    margin: 0;
}
.map-heading-dropdown-item h4:hover{
    color:rgb(101, 149, 171);
    cursor:pointer;
}
.map-heading-dropdown-item h5{
    font-size: 12px !important;
    font-family: "Poppins" !important;
    color: rgb(147, 147, 147) !important;
    margin: 0;
}
.map-filters-map-heading-heading h3,h5{
    margin:0
}
.map-heading-select{
    margin-top: 33%;
    font-size: 15px;
    margin-left: 7px;
}

.map-filters-search-pane{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    order:1;
    width:50%;
}
.map-filters-filters{
    display: flex;
    flex-direction: row;
    width:50%;
    justify-content: space-between;
    order: 2;
    height: 100%;
    align-items: center;
}
.map-filters-filter{
    display: flex;
    flex-direction: row;
    width:23%;
}
.map-filters-search-pane input{
    height:37px;
    width: 100%;
    border-radius: 40px;
    background-color: #d8d8d8;
    outline: none;
    font-size: 12px;
    border:none;
    padding-left: 20px;
    font-family: "Poppins";
}
.css-tr4s17-option ,.css-d7l1ni-option,.css-10wo9uf-option{
    font-size: 13px !important;
    text-align: left;
}
.css-tr4s17-option{
    background-color:rgb(54, 177, 196) !important ;
    border-radius: 7px;
}

.fact{
    display: flex;
    flex-direction: row;
    height:100%;
    width:25%;
    justify-content: left;

}
.fact-figure{
    display: flex;
    flex-direction: row;
    order: 1;
    justify-content: left;
    align-items: center;
    width:max-content;
    margin-right: 20px;
    ;
}.fact-figure h1{
    font-size: 30px;
    margin:0;
    color:rgb(44, 104, 113);

}
.selectmap{
    position: absolute;
    width:10%;
    height:max-content;
    padding: 10px 0;
    background-color: #ffffff;
    z-index: 2;
    top:20px;
    left: 20px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.mapheading{
    position: absolute;
    width:60%;
    height:20px;
    padding: 10px 0;
    background-color: #fff;
    top:20px;
    left: 20px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center; 
}
.map-settings{
    color:#277529;
    font-weight: 600;
}
.map-heading{
    color:#454545;
    font-weight: 600;
}
.map-settings:hover{
    cursor: pointer;
}
.select-map-item{
height:20%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
margin-right: 10px;
}
.select-map-item-no-data{
height:20%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
margin-right: 10px;
border-left: 1px solid rgb(103, 103, 103);
padding-left: 10px;
}
.select-map-item p{
    font-size: 12px;
    margin-left: 10px;
}
.select-map-item-no-data p{
    font-size: 12px;
    margin-left: 10px;
}
.insigts-icon{
    font-size: 15px;
    margin-right: 10px;
}
.fact-narration{
    display: flex;
    flex-direction: row;
    order: 2;
    justify-content: left;
    align-items: center;
    width:60%;
    color:rgb(60, 60, 60);
}
.fact-narration h5{
    font-size: 13px;
    text-align: left;
    font-weight: 300;
    margin:0
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
border-radius: 0 0 10px 10px !important;
}
.nodes-button{
    display: flex;
    flex-direction: row;
    height:60px;
    width: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position:absolute;
    bottom:5vh;
    left:5vw;
    z-index: 1000;
    background-color: #fffffff5;
    cursor: pointer !important;
    box-shadow: -2px -2px 17px -1px rgba(107,107,107,0.74);
-webkit-box-shadow: -2px -2px 17px -1px rgba(107,107,107,0.74);
-moz-box-shadow: -2px -2px 17px -1px rgba(107,107,107,0.74);
}
.nodes-button h3{
    margin-top: 33%;
}
.nodes-button:hover h3{
    color:rgb(60, 104, 123);
}
.add-slides-button{
    display: flex;
    flex-direction: row;
    height:60px;
    width: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position:absolute;
    bottom:5vh;
    left:10vw;
    z-index: 1000;
    background-color: #fffffff5;
    cursor: pointer !important;
    box-shadow: -2px -2px 17px -1px rgba(107,107,107,0.74);
-webkit-box-shadow: -2px -2px 17px -1px rgba(107,107,107,0.74);
-moz-box-shadow: -2px -2px 17px -1px rgba(107,107,107,0.74);
}
.add-slides-button h3{
    margin-top: 33%;
}
.add-slides-button:hover h3{
    color:rgb(60, 104, 123);
}
.slide-bottom-panel{
    display: flex;
    flex-direction: row;
    position:relative !important;
    height:100% !important;
    width: 100%;
    order: 2;
    justify-content: center;
}
.mapdiv{
    display:flex;
    /* order:2; */
    flex-direction:column;
    height:65vh;
    width:90vw !important;
    position: relative;
    justify-content: center;
    align-items: center;
    /* overflow: visible; */
}
.floating-menu{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100vh;
}
.mapboxgl-popup-content{
    min-width:250px !important;
    height:max-content;
    background-color: #ffffffeb;
    border-left:3px solid #B6D58D;
}
.mapboxgl-map{
    /* border-radius: 0 20px 0 0 !important; */

}
.right-panel{
    display:flex;
    /* order:3; */
    flex-direction:column;
    height:100% !important;
    width:22vw;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    left: 0 !important;
    top: 0 !important;
    z-index: 10;

}
.left-panel{
    display:flex;
    order:1;
    flex-direction:column;
    height:65vh;
    width:20vw;
    justify-content: flex-start;
    align-items: flex-start; 
    position: absolute;
    left: 5vw;
    top: 50px;
    z-index: 100;
}
.instructions{
    display: flex;
    flex-direction: row;
    height:60%;
    width:90%;
    justify-content: center;
    align-items: center;
    margin-top:0;
}
.instructions-div{
    display: flex;
    flex-direction: row;
    width:80% !important;
    height: 100%;
}
.instructions-div hr{
    background-color:blue
}
.instructions-input{
    width:95%;
    height:30px;
    border:1px solid rgb(99, 159, 208);
    background-color: transparent;
    border-radius: 7px;
    color:rgb(64, 131, 162);
    padding-left: 10px;
    outline: none;
}
.instructions-alert{
    text-align: left;
    width: 90%;
}
.right-facts-heading{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 10%;
}
.right-facts-heading h3{
    font-size: 18px;
    font-family:'Cera Pro2' ;
}
.right-facts-container{
display: flex;
flex-direction: row;
height:20%;
width:90%;
}
.css-b62m3t-container{
    width:90%;
}
.css-13cymwt-control{
height: 50px;
font-size: 12px;
background-color: #F6F6F6;
}
.right-facts-left{
    display: flex;
    flex-direction: column;
    height:100%;
    order: 1;
    width: 50%;
    justify-content: space-between;
    align-items: center;
margin: 0;

}
.right-facts-right{
    display: flex;
    flex-direction: column;
    height:100%;
    order: 2;
    width: 50%;
    justify-content: space-between;
    align-items: center;
margin: 0;

}
.right-facts-fact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height:140px;
    width:80%
}
.right-facts-fact h2{
    font-size: 40px;
    text-align: left;
    color:rgb(44, 104, 113);
    margin:0
    
}
.right-facts-fact h5{
    font-size: 13px;
    text-align: left;
    margin:0;
    color:rgb(60, 60, 60);
    font-weight: 300;
}
.show-schools-button{
    width:100%;
    height:35px;
    border-radius: 10px;
    background-color: rgb(44, 104, 113);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.kpi-fact{
    font-weight: 500;
    color:rgb(44, 104, 113);
}
.show-schools-button h5{
    font-size: 12px;
}
.schoolinfo{
    display:flex;
    flex-direction:column;
    width:30vw;
    height:100vh;
}
.schoolsdiv{
    width:inherit;
    height:60vh;
    overflow-y: scroll;
}
.schoolnamepopup{
    color:#438C46;
    font-weight:600;
    font-size: 14px;
    font-family: 'Cera Pro2';
}
.schoolpopulation{
    font-family: 'Cera Pro2';
    font-size: 40px;
    color:#484848;
    font-weight: 700;
}
.oneschool{
    width:90%;
    height:96px;
    background-color:#F9F7F7;
    margin-left:15px;
    border-right:15px solid rgb(129, 223, 148);
    display:flex;
    flex-direction:row;
    flex-shrink: 0;
    cursor:pointer;
    border-radius:5px;
    margin-bottom:10px;
}
.oneschoolclicked{
    outline:1px solid rgb(110, 143, 219);
}

.singleschoolnfo{
    display:flex;
    flex-direction:column;
    width:30vw;
    height:100vh;
}
.back-button-school{
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    margin:60px 0 20px 10px;
    width:50%;
    height:50px;
    order:1;
}

.back-button-circle{
    height:100%;
    width:50px;
    border-radius:50%;
    /* background-color:rgb(228, 228, 228); */
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
}
.school-name-div{
    display:flex;
    flex-direction:row;
    order:2;
    margin-top:30px;
    width:90%;
    height:50px;
}
.school-icon{
    order:1;
    display:flex;
    height:inherit;
    flex-basis:20%;
    flex-direction: row;
    align-items:center;
    justify-content:center;
    margin-right:10px;
}
.schoolicon{
    color:#4C7ABD;
    font-size:50px;
}
.school-name-details{
    order:2;
    display:flex;
    flex-direction:column;
    height:inherit;
    flex-basis:80%;
    justify-content:center;
    align-items:flex-start;
}
.school-name{
    order:1;
    color:#4C7ABD;
    margin:0;
    font-size:16px;
    font-family:'Cera Pro2'
}
.school-meta{
    display:flex;
    flex-direction:row;
    order:2;
}
.meta{
    width:max-content;
    padding:5px;
    background-color:#3bc76a3f;
    color:#3BC76A;
    height:15px;
    font-family:'Cera Pro2';
    margin-right:10px;
    border-radius:8px;
}
.meta p{
    font-size:9px;
}
.add-site{
    display:flex;
    flex-direction:column;
    height:40px;
    width:90%;
    border-radius:10px;
    order:3;
    background-color:rgb(76, 147, 76);
    color:#fff;
    cursor:pointer;
    margin:30px 0 0 20px;
    justify-content:center;
    align-items:center;

}
.add-site:hover{
    background-color:rgb(67, 67, 136);
}
.directions{
    font-size:14px;
    color:rgb(80, 140, 195);
    cursor:pointer;
    font-family:'Cera Pro2';
    margin-top:0;
    margin-bottom:10px;
}
.map-help{
    display:flex;
    background-color:#fff;
    width:100px;
    height:30px;
    position:absolute;
    top:8vh;
    right:20px;
    flex-direction: row;
    align-items:center;
    justify-content:center;
    cursor:pointer;
}
.helpdiv{
    margin:30px;
}
.map-help:hover{
    background-color:rgb(81, 152, 81);
    color:white;
    font-family:'Cera Pro2';
    font-weight:500
}
.inticon{
    color:rgb(126, 226, 126);
    font-size: 25px;
    z-index:100;
}
.directions:hover{
text-decoration: underline;
}
.school-population-div{
    display:flex;
    flex-direction:row;
    width:90%;
    margin:30px 0 0 20px;
    background-color:#81a6bb1e;
    height:80px;
    border-radius:10px;
    order:3; 
}
.population-div-1{
    display:flex;
    flex-direction:column;
    order:1;
    flex-basis: 50%;
    padding:10px;
    justify-content:center;
    align-items:flex-start;
    margin-left:20px;
}
.population-div-1 h4{
    font-family:'Cera Pro1';
    font-size:12px;
    color:#4C7ABD;
    margin:0;
}
.population-div-1 h1{
    font-family:'Cera Pro2';
    font-size:42px;
    color:#4C7ABD;
    margin:0
}
.population-div-2{
    display:flex;
    flex-direction:column;
    order:2;
    flex-basis: 50%;
    padding:10px;
    justify-content:center;
    align-items:flex-start;
}
.population-div-2 h4{
    font-family:'Cera Pro2';
    font-size:12px;
    color:#4C7ABD;
    margin:0 0 3px 0;
}
.water-sanitation-div{
    display:flex;
    flex-direction:column;
    width:90%;
    order: 4;
    height:60vh;
    margin:20px 0 0 20px

}
.water-sanitation{
    display:flex;
    flex-direction:row;
    height:5%;
    font-size:10px;
    font-family:'Cera Pro2';
    color:#4C7ABD;
    order:1;
    cursor:pointer;
}
.water-sanitation h4{
    margin:0 20px 0 0;
}
.water-sanitation-info{
    display: flex;
    flex-direction:column;
    width:100%;
    height:95%;
    background-color: #F5F5F5;
    order:2;
    align-items:flex-start;
    overflow-y: scroll;
}
.water-source{
font-size:15px;
color:#4C7ABD;
font-family: 'Cera Pro2';
margin:20px 0 0 20px;
order:1;
}
.functionality-div{
    display:flex;
    flex-direction:row;
margin:5px 0 0 20px;
order: 2;
    height:20px;
    justify-content:center;
    align-items:center;
}
.functionality{
    padding:5px;
    background-color:#d05c5c37;
    color:#D05C5C;
    width:max-content;
    border-radius:5px;
    height:15px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
}
.reliable{
    padding:5px;
    background-color:#7fd05c37;
    color:#60d05c;
    width:max-content;
    border-radius:5px;
    height:15px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
}
.unreliable{
    padding:5px;
    background-color:#d07d5c37;
    color:#d06b5c;
    width:max-content;
    border-radius:5px;
    height:15px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
}
.functionality-div h4{
    font-size:9px;
    margin-right:20px;
    margin:0
}
.borehole-name{
    order:3;
    display:flex;
    width:80%;
    height:max-content;
    flex-direction:column;
    font-family: 'Cera Pro2';
    margin:5px 0 0 20px;
    align-items:flex-start;
}
.borehole-name-row{
    order:3;
    display:flex;
    width:80%;
    height:max-content;
    flex-direction:row;
    font-family: 'Cera Pro2';
    margin:5px 0 0 20px;
    align-items:flex-start;
}
.interventionschool{
    background-color:rgb(79, 129, 79);
    color:white;
    border-right:15px solid rgb(154, 220, 135);
}
.borehole-image{
    width:inherit;
    height:200px;
    border-radius:10px;
}
.alternative-water{
    margin-left:20px !important;
}
.back-arrow{
    font-size:20px;
    color:#000;
    cursor:pointer;
}
.back-arrow:hover{
    color:rgb(109, 172, 109);
}
.schooldetails{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    flex-basis: 60%;
    order:1;
    margin:0;
    margin-left:10px;
}
.schooldetails h4{
    margin-bottom:10px;
    font-family: 'Cera Pro2';
    font-size:13px;
}
.schooldetails h6{
    margin-top:0;
    font-family: 'Cera Pro1';

}
.spring{
    display:flex;
    flex-direction:row;
    align-items:center;
    flex-basis:20%;
    order:2;
}
.springicon{
    font-size:25px ;
    color:#6c89d4;
}
.springiconunsafe{
    font-size:25px ;
    color:#da9740;
}
.borehole{
    display:flex;
    flex-direction:row;
    align-items:center;
    flex-basis:20%;
    order:3;
}
.boreholeicon{
    font-size:25px ;
    color:#6c89d4;
}
.boreholeiconunsafe{
    font-size:25px ;
    color:#da9740;
}
.marker{
    height:20px;
    width:20px;
    cursor:pointer;
}
.data-layer{
    cursor:pointer;
}
.map-popup{
    font-size:20px;
    color:white;
}
.Improved{
    color:rgb(99, 179, 237);
}
.Unimproved{
    color:rgb(220, 144, 51);
}
.map-borehole{
    font-size:20px;
    color:rgb(224, 88, 88);
}
.map-spring{
    font-size:20px;
    color:rgb(58, 155, 207);
}

.filterschoolshow{
    width:100%;
    margin-top:30px;

}
.filterdiv{
    display:flex;
    flex-direction:column;
    margin:15px;
}
#demo-simple-select-label #demo-simple-select .menuitem .fctrl .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    font-family: Cera Pro2 !important;
    text-align: left;
    font-size: 12px !important;
}
.fctrl{
    margin-top:20px;
}
.downloadbutton{
    display:flex;
    flex-direction:column;
    justify-content:center;
    background-color: #438C46;
    color:white;
    border-radius: 10px;
    font-family: Cera Pro2;
    height:50px;
    cursor: pointer;
}
.downloadbutton:hover{
    background-color:#415DA5;
}
.dot1 {
    height: 12px;
    width: 12px;
    margin:2px;
    background-color:#4982AC;
    border-radius: 50%;
    display: inline-block;
  }
  .dot0 {
    height: 12px;
    width: 12px;
    margin:2px;
    background-color:#E08F31;
    border-radius: 50%;
    display: inline-block;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  width: 0px;
  background-color: rgba(255, 255, 255, 0);
}
.blobImproved {
	background: #4680C5;
	border-radius: 50%;
	margin: 10px;
	height: 15px;
	width: 15px;
	box-shadow: 0 0 0 0 #467fc550;
	transform: scale(1);
	animation: pulse 2s infinite;
}
.less25{
    background: #c54646b5;
	border-radius: 50%;
    border:1px solid white;
	margin: 10px;
	height: 15px;
	width: 15px;
}
.less50{
    background: #c57d46bc;
	border-radius: 50%;
    border:1px solid white;
	margin: 10px;
	height: 15px;
	width: 15px;
}
.less75{
    background: #ead769a7;
	border-radius: 50%;
    border:1px solid white;
	margin: 10px;
	height: 15px;
	width: 15px;
}
.less100{
    background: #d6f29694;
	border-radius: 50%;
    border:1px solid white;
	margin: 10px;
	height: 15px;
	width: 15px;
}
.actual100{
    background: #35c55294;
	border-radius: 50%;
    border:1px solid white;
	margin: 10px;
	height: 15px;
	width: 15px;
}
.blobUnimproved {
	background: #E2AA55;
	border-radius: 50%;
	margin: 10px;
	height: 15px;
	width: 15px;

	box-shadow: 0 0 0 0 #467fc550;
	transform: scale(1);
	animation: pulseunimproved 2s infinite;
}
.blobintervention{
    background: #c84747;
	border-radius: 50%;
	margin: 10px;
	height: 15px;
	width: 15px;

	box-shadow: 0 0 0 0 #c8474750;
	transform: scale(1);
	animation: pulseintervention 2s infinite; 
}
.pallet-no-data{
    display: flex;
    flex-direction: row;
    height: 15px;
	width: 15px;
    background: #ccc;
}
.pallet-1-20{
    display: flex;
    flex-direction: row;
    height: 15px;
	width: 15px;
    background: #341363;
}
.pallet-1-40{
    display: flex;
    flex-direction: row;
    height: 15px;
	width: 15px;
    background: #8C297B;
}
.pallet-1-60{
    display: flex;
    flex-direction: row;
    height: 15px;
	width: 15px;
    background: #D74D69;
}
.pallet-1-80{
    display: flex;
    flex-direction: row;
    height: 15px;
	width: 15px;
    background: #FB8E68;
}
.pallet-1-100{
    display: flex;
    flex-direction: row;
    height: 15px;
	width: 15px;
    background: #FED69A;
}
.pallet-2-20{
    display: flex;
    flex-direction: row;
    height: 15px;
	width: 15px;
    background: #E4EDB4;
}
.pallet-2-40{
    display: flex;
    flex-direction: row;
    height: 15px;
	width: 15px;
    background: #D3E4A6;
}
.pallet-2-60{
    display: flex;
    flex-direction: row;
    height: 15px;
	width: 15px;
    background: #9EC87A;
}
.pallet-2-80{
    display: flex;
    flex-direction: row;
    height: 15px;
	width: 15px;
    background: #409B32;
}
.pallet-2-100{
    display: flex;
    flex-direction: row;
    height: 15px;
	width: 15px;
    background: #256D1E;
}
/* @keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #4680C5b3;
	}

	70% {
		transform: scale(1.2);
		box-shadow: 0 0 0 10px #4680C500;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #4680C500;
	}
}
@keyframes pulseunimproved {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #E2AA55b3;
	}

	70% {
		transform: scale(1.2);
		box-shadow: 0 0 0 10px #E2AA5500;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #E2AA5500;
	}
}
@keyframes pulseintervention {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #c84747b3;
	}

	70% {
		transform: scale(1.2);
		box-shadow: 0 0 0 10px #4E8A5000;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #4E8A5000;
	}
} */

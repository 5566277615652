.water-slide-div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
}
.water-page-callout-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    height:150px;
    order: 2;
}
.water-page-water-map{
    display: flex;
    flex-direction: row;
    position: relative;
    height:70vh;
    width:100vw;
    order: 3;
}
.inner-page-paragraph{
    display: flex;
    flex-direction: row;
    width:45%;
    order:3;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin-top:70px;
}
.inner-page-paragraph p{
font-family:  'Georgia';
    /* font-family:  source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif; */
    font-size: 17px;
    color:#3b3b3b !important;
    line-height: 1.7;
    font-weight: 300;
}
.water-page-water-map-details{
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height:90%;
    width:25%;
    right:10px;
    background-color: #fff;
    z-index: 10;
    top:5%;
    border-radius: 8px;
}
.water-page-water-map-settings{
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height:100px;
    width:15%;
    left:10px;
    background-color: #fff;
    z-index: 10;
    top:5%;
    border-radius: 8px;
    padding:15px
}
.water-page-water-map-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    height:50px;
    order: 1;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
    overflow-wrap: break-word;
}
.water-page-households-container{
    display: flex;
    flex-direction: column;
    height:90%;
    overflow: scroll;
    order: 2;
    width: 90%;
}
.single-household{
    display: flex;
    flex-direction: column;
    width: 98%;
    height:100px;
    background-color: #f9f9f9ce;
    border-radius: 7px;
    border-left: 6px solid #f0c89bc9;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
    flex-shrink: 0;
}
.single-household h4{
    font-size: 9px;
    font-weight: 400;
}
.single-household h5{
    font-size: 10px;
    color:#4D4E4D
}
.single-household-name{
    border-bottom: 1px solid #EDEDED;
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: flex-start !important;
}
.household-complete h5{
    color:#5D9C60 !important
}
.single-household-name h4{
    margin:0;
    text-align: left;
    font-size: 11px;
}
.single-household-name,.single-household-status{
    order:1;
    display: flex;
    flex-direction:row;
    height:50%;
    width:90%;
    justify-content: flex-start;
    align-items: center;
}
.household-icon-holder{
    display: flex;
    flex-direction: row;
    width:30px;
    height: 30px;
    border-radius: 50%;
    background-color: #F2ECEC;
    align-items: center;
    justify-content: center;
}
.user-household{
    font-size: 12px;
    color:orange;
}
.household-icon-holder-opposite{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-left: 10px;
}
.household-icon-holder-opposite h5{
    font-size: 13px;
}
body {
  height:100%;
  width:100%;
  margin: 0;
  font-family: "Fira Sans Condensed" !important
}
h1,h2,h3{
  font-family: "Fira Sans Condensed" !important;
  font-weight: 700;
}
h4,h5{
  font-family:  "Fira Sans Condensed" !important;
  font-weight: 500;
}
p{
  font-family: "Georgia";
  font-weight: 300;
}
@font-face {
	font-family: "Cera Pro2";
	src: local("Cera Pro2"), url(./fonts/Cera\ Pro\ Medium.otf);
}
@font-face {
	font-family: "Cera Pro1";
	src: local("Cera Pro1"), url(./fonts/Cera\ Pro\ Light.otf);
}
@font-face {
	font-family: "Cera Pro3";
	src: local("Cera Pro3"), url(./fonts/Cera\ Pro\ Bold.otf);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.paragraph-content{
    display: flex;
    flex-direction: row;
    width:45%;
    order:1;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin-top:20px;
}
.paragraph-content p{
    font-family:  'Georgia';
    /* font-family:  source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif; */
    font-size: 17px;
    color:#3b3b3b !important;
    line-height: 1.7;
    font-weight: 300;
}
.content-heading{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    order:1;
    width:45%;
}
.content-heading h2{
    font-family: "Fira Sans Condensed";
}
.slide-1-left-panel-header{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height:15%;
    width:80%;
    /* border-bottom: 1px solid rgb(203, 203, 203); */
    margin-top: 20px;
    margin-bottom:10px;
}
.slide-1-left-panel-header h4{
    font-size: 14px;
    margin: 0;
    text-align: left;
}
.slide-1-left-panel-header h6{
    font-size: 12px;
    font-weight: 300;
    margin-top: 5px;
    text-align: left;

}

.home{
    height:100vh;
    width:100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.logo{
    position:absolute;
    top:20px;
    left:20px;
    max-width:300px;
    height:30px;
}
.homecontainer{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    height:100%;
    width:100%;
}
.home1{
    display:flex;
    flex-direction:row;
    order:1;
    flex-basis: 50%;
    height:100vh;
    background:url('../../Images//wellfairhome.png');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 200;
}
.home2{
    display:flex;
    flex-direction:column;
    order:2;
    flex-basis: 50%;
    justify-content:center;
    align-items:center;
    text-align:left;
    height:100%

}
.home2 img{
    height:60px
}
.home2 h1{
    font-size:40px;
    margin-bottom:10px;
}
.home2 h4{
    font-size:16px;
    margin-top:0px;
}
.logo-div-homepage{
    display: flex;
    flex-direction: row;
    order:1;
    height:10%;
    margin-bottom: 20px;
}
.sigin-div-homepage{
    display: flex;
    flex-direction: column;
    order: 2;
    height: 40%;
    width:50%;
}
.textinput-login{
    border-radius: 7px;
    border: .5px solid #9EA9B0;
    outline: none;
    height: 47px;
    width:95%;
    margin-bottom: 15px;
    padding-left: 15px;
  }
  .submit-button-signin{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 47px;
    width:100%;
    border-radius: 7px;
    background-color:#396FD7;
    color: #fff;
    margin-top: 20px;
  }
  .dashboard-button-signin{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 47px;
    width:100%;
    border-radius: 7px;
    background-color: #fff;
    border:#307d76 1px solid;
    color: #307d76;
    margin-top: 20px;
  }
  .dashboard-button-signin:hover{
    cursor:pointer;
    background-color: #307d76;
    border:#307d76 2px solid;
    color: #fff;
  }
  .submit-button-signin p{
    font-size: 13px;
    font-weight: 500;
  }
  .dashboard-button-signin p{
    font-size: 13px;
    font-weight: 500;
  }
.signin{
    height:50px;
    width:300px;
    background:white;
    border-radius:0;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.signin:hover{
    background-color:black;
    color:white;
}
.signin h3{
    font-size: 16px;
    vertical-align: middle;
    margin-left:20px;
    font-family: Cera Pro2;
}
.task-notification{
    margin-bottom:10px;
    cursor:pointer;
}
.userbadge{
    display:flex;
    flex-direction:row;
    position:absolute;
    top:10px;
    right:-30px;
    align-items:center;
    margin:0;
    cursor:pointer;
    width:250px;
    color:rgb(30, 29, 29);
    height:20px;

}
.userbadge:hover{
background-color: white;
color:black;
border-radius:20px;
}
.userdetails{
    display:flex;
    flex-direction:column;
    justify-content:center;
    margin-left:10px;
}
.userdetails h5,p{
    margin:0;
    text-align:left;
}
.userdetails h5{
    font-size:12px;
}
.userdetails p{
    font-size:10px;
}
.dropdown-container{
    display: flex;
    flex-direction: row;
    width:80%;
    height:50px;
    z-index: 100;
    margin-bottom: 0 0 5px 0;
}
.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root{
    font-family: 'Cera Pro1';
    font-size: 10px !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    font-family: 'Cera Pro1' !important;
    font-size: 13px !important;

}
.css-cyfsxc-MuiPickersCalendarHeader-labelContainer {
    font-family: 'Cera Pro1' !important;
}
.css-innj4t-MuiPickersYear-yearButton {
    font-family: 'Cera Pro1' !important;
}
.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root{
    font-family: 'Cera Pro1' !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    font-family: 'Cera Pro1' !important;
    font-size: 10px !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
    font-family: 'Cera Pro1' !important;
    font-size: 12px !important;
}